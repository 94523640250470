// Open mobile menu
document.querySelector("[aria-controls='primary-nav']").addEventListener("click", function (event) {
  const button = event.target;
  const state = button.getAttribute("aria-expanded");
  const isOpen = state === "true";
  const mobileMenu = document.getElementById("primary-nav");
  const body = document.querySelector("body");

  button.setAttribute("aria-expanded", isOpen ? "false" : "true");
  button.innerText = isOpen ? "Menu" : "Close";
  body.classList.toggle("overflow-hidden");
  mobileMenu.classList.toggle("hidden");
});

// Calculate if mobile menu being used
let isMobile = document.querySelector("[aria-controls='primary-nav']").offsetParent !== null;
let resizeTimer;

function reizeHandler() {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    isMobile = document.querySelector("[aria-controls='primary-nav']").offsetParent !== null;
  }, 1000);
}

window.addEventListener("resize", reizeHandler);


// Handle primary nav hover/clicks
const primaryParents = document.querySelectorAll(".site-header:not(.menu-open) nav > ul > .isParent");

primaryParents.forEach(primary => {
  primary.addEventListener("mouseenter", event => {
    !isMobile && openMenu(primary);
  });

  primary.addEventListener("mouseleave", event => {
    !isMobile && closeMenu(primary);
  });

  primary.querySelector("button").addEventListener("click", event => {
    event.stopPropagation();
    toggleMenu(primary);
  });

  primary.addEventListener("click", event => {
    toggleMenu(primary);
  });

  primary.querySelector(":scope > a")?.addEventListener("click", event => {
    if (isMobile) {
      event.preventDefault();
    } else {
      return;
    }
  });

});

function toggleMenu(parent) {
  if (parent.classList.contains("open")) {
    closeMenu(parent);
  } else {
    openMenu(parent);
  }
};

function openMenu(parent) {
  const button = parent.querySelector("button");

  parent?.classList.add("open");
  button?.setAttribute("aria-expanded", "true");
}

function closeMenu(parent) {
  const button = parent.querySelector("button");

  parent?.classList.remove("open");
  button?.setAttribute(`aria-expanded`, `false`);
}

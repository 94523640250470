// This function just basically "clicks" the first child link inside
document.querySelectorAll(".clicker").forEach(fakeLink => {
  fakeLink.addEventListener("click", event => {
    if (event.target.tagName === "A") return;

    event.preventDefault();
    fakeLink.querySelector("a").click();
  }, true);
});

document.querySelectorAll("article [data-start][data-end]").forEach(statusElement => {
  const now = new Date();
  const start = new Date(statusElement.getAttribute("data-start"));
  const end = new Date(statusElement.getAttribute("data-end"));
  if (now > end) {
    statusElement.remove();
  } else if (start > now) {
    statusElement.textContent = "Coming Soon";
  } else {
    statusElement.textContent = "Now on view";
  }
});

document.querySelectorAll(".alert-bar").forEach(alertBar => {
  const alertBarClose = alertBar.querySelector("button");

  if (document.cookie.split(";").some((item) => item.trim().startsWith("noalert="))) {
    alertBar.classList.add("hidden");
  } else {
    alertBarClose.addEventListener("click", () => {
      let expire = new Date();
      let days = 180;
      expire.setTime(expire.getTime() + (days * 24 * 60 * 60 * 1000));
      document.cookie = `noalert=1; expires=${expire.toUTCString()}`;
      alertBar.classList.add("hidden");
    });
  }
});

document.querySelectorAll(".slider-container").forEach(slider => {
  const slides = slider.querySelectorAll(".slide");

  if (slides.length < 2) {
    slider.querySelector("nav")?.remove();
    return;
  }

  const viewport = slider.querySelector(".viewport");
  const prev = slider.querySelector("a.prev");
  const next = slider.querySelector("a.next");

  let activeSlide = 0;

  prev.addEventListener("click", event => {
    event.preventDefault();
    if (activeSlide <= 0) return;
    --activeSlide;
    adjustSlides();
  });

  next.addEventListener("click", event => {
    event.preventDefault();

    if (activeSlide >= (slides.length - 1)) return;

    ++activeSlide;
    adjustSlides();
  });

  function adjustSlides() {
    let offset = slides[activeSlide].offsetLeft;
    viewport.style.transform = `translateX(-${offset}px)`;
  }
});
